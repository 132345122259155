import React from 'react';
import Post from '../../components/post';
import LazyLoadImage from '../../components/LazyLoadImage';


const HowItWorksCreditCounselingPage = () => {
    const article = {
        id: '701d055c-4adc-5aec-b27a-151ecbfa464f',
        title: 'How Credit Counseling Works, and How It Can Help',
        slug: '/credit-counseling/how-it-works/',
        date: '2018-01-24T18:35:02.000Z',
        modified: '2021-11-09T14:31:58.000Z',
        excerpt: 'Working with a credit counselor is one important way that many people can get their finances under control. We&#8217;ll explore what they can help with, and why you might need one.',
        featured_image: {
            source_url: '/media/copy-credit-counseling-guide-0.png',
        },
        acf: {
            seo_title: '',
            robots: 'index, follow',
        },
        read_time_minutes: 9,
        content: (
            <>
                <p>
                    Do you struggle to make the minimum payments on your
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=credit-counseling-how-it-works&amp;sub2=credit-cards" rel="noopener noreferrer" target="_blank">credit cards</a>
                    {' '}
                    or pay your bills on time?
                </p>
                <p>Do you find yourself making late payments time and time again, or juggling your bills to decide which one to pay on time this month?</p>
                <p>According to the Federal Reserve, Americans had a total of $935.6 billion in revolving debt at the end of 2015, and this number hasn&rsquo;t been improving. </p>
                <p>If you find yourself making late payments on your bills, you might want to consider seeing a credit counselor. </p>
                <p>
                    Credit counseling is one of the ways that people can get help to get out of
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=credit-counseling-how-it-works&amp;sub2=credit-card" rel="noopener noreferrer" target="_blank">credit card</a>
                    {' '}
                    debt.
                </p>
                <p>Credit counseling offers solutions and advice to help you manage your debt, and will often intercede on your behalf to the credit card companies and debt collectors. </p>
                <p>But it&rsquo;s important to understand what a credit counselor can and cannot do. </p>
                <p>We&rsquo;ve broken down the services credit counselors can offer, as well as a few common myths. </p>
                <p><LazyLoadImage src="/media/employment-of-credit-counselors-by-state-1.png" alt="Employment of Credit Counselors by State" /></p>
                <h2 id="credit-counselors-can-offer-financial-advice-with-no-fees">Credit counselors can offer financial advice with no fees</h2>
                <p>Credit counseling agencies are almost always non-profit organizations that typically do not charge upfront fees&nbsp;for their work. </p>
                <p>The credit counseling service makes money by getting paid fees from the creditors (banks and credit card companies) that benefit from getting repaid by customers – instead of having their customers declare bankruptcy and fail to pay back their credit card debts.</p>
                <p>
                    When you work with a
                    {' '}
                    <a href="https://www.debtconsolidation.com/credit-counseling/consumer-credit-counseling/">consumer credit counseling service</a>
                    , they will give you free financial counseling by taking a look at your overall financial life, talk with you about your monthly spending, and help you set up a monthly budget.
                </p>
                <p>This basic financial counseling can be a helpful first step for many people. </p>
                <p>
                    According to the
                    {' '}
                    <a href="http://www.cambridge-credit.org/pdfs/Transparency6.pdf">Cambridge Credit Counseling Transparency Report</a>
                    , 94% of their clients said working to set up a budget and getting help with analyzing their spending was helpful to them in managing their finances.
                </p>
                <p>If you need additional help, a credit counseling service will take the next step of talking with you about a Debt Management Plan.</p>
                <h3>The myth: you will only get expensive financial advice from your credit counselor</h3>
                <p>Because the word counseling is in the title, many people assume that credit counseling is only getting advice from someone about how to get out of debt. </p>
                <p>Different credit counseling agencies offer different services and approaches for their clients. </p>
                <p>&quot;Our members provide clients with written action plans with various steps so they can take concrete steps of reaching out to creditors and negotiating fees,&quot; says Gail Cunningham, spokesperson for the National Foundation for Credit Counseling (NFCC). </p>
                <p>Before signing up for credit counseling, determine what type of services you need and ask questions to determine which organization&rsquo;s approach fits best with your situation.</p>
                <p>The idea that credit counseling services are expensive is a potential barrier to people considering them, with about one in five respondents to the National Foundation for Credit Counseling 2013 Financial Literacy Study saying cost might prevent them from getting help.</p>
                <p>Some nonprofit credit counseling agencies offer free or inexpensive counseling services, including more than 750 locations associated with NFCC. </p>
                <p>&quot;The vast majority of our members do not charge any fee for services and those that do require payment are typically in the $20 range. One of our member quality standards is that no person can be turned away because of inability to pay. If someone truly cannot pay even $20, then the fee is waived,&quot; says Gail Cunningham, spokesperson for the NFCC.</p>
                <h2 id="credit-counselors-can-set-you-up-with-a-debt-management-plan">Credit counselors can set you up with a Debt Management Plan</h2>
                <p>Credit counseling can help you decide the best way to approach your credit card debt. </p>
                <p>
                    It might make sense to repay your
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=47&amp;sub1=credit-counseling-how-it-works&amp;sub2=credit-card" rel="noopener noreferrer" target="_blank">credit card</a>
                    {' '}
                    debt with a Debt Management Plan.
                </p>
                <p><LazyLoadImage src="/media/average-credit-card-debt-in-2017-2.png" alt="Average Credit Card Debt in 2017" /></p>
                <p>The way a Debt Management Plan works is that the consumer agrees to repay debts over a period that often&nbsp;lasts up to five years, while making a single consolidated payment each month. </p>
                <p>The credit counseling service manages the repayment of your various debts out of this one monthly combined payment. </p>
                <p>When you agree to sign up for a Debt Management Plan, the banks and credit card companies often agree to waive fees and lower your interest rates, which makes it more affordable to pay down your debts. </p>
                <p>
                    For example, according to the
                    {' '}
                    <a href="http://www.cambridge-credit.org/pdfs/Transparency6.pdf">Cambridge Credit Counseling transparency report</a>
                    , clients &quot;received interest rate reductions averaging 14.49%. As a result, the average new client&rsquo;s payment was $141.58 less than what they had been paying on their own.&quot;
                </p>
                <h3>The myth: credit counselors will wipe out your debt</h3>
                <p>When working with a credit counseling service, your debt is not automatically forgiven or &quot;wiped out.&quot; </p>
                <p>
                    However, one option for consumers who are unable to repay the full amount that they owe each month is for a credit counseling agency to
                    {' '}
                    <a href="https://www.debtconsolidation.com/debt-management/">set them up on a Debt Management Plan (DMP).</a>
                </p>
                <p>
                    &quot;In a DMP, you deposit money each month with the credit counseling organization. It uses your deposits to pay your unsecured debts, like your credit card bills,
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=44&amp;sub1=credit-counseling-how-it-works&amp;sub2=student-loans" rel="noopener noreferrer" target="_blank">student loans</a>
                    {' '}
                    and medical bills, according to a payment schedule the counselor develops with you and your creditors. Your creditors may agree to lower your interest rates or waive certain fees,&quot; according to the Federal Trade Commission.
                </p>
                <p>&quot;But it&rsquo;s a good idea to check with all your creditors to be sure they offer the concessions that a credit counseling organization describes to you.&quot; </p>
                <p>Andrew Housser says that the agencies maintain pre-arranged agreements with credit card companies that allow them to lower interest rates on existing debt to a creditor-issued &quot;concession rate.&quot;</p>
                <h2 id="credit-counselors-can-help-improve-your-credit-score">Credit counselors can help improve your credit score</h2>
                <p>Credit counseling and debt management plans are often confused with debt settlement services. </p>
                <p>The difference between a debt management plan and a debt settlement service, is that with a Debt Management Plan, you agree to repay the full amount of debt that you owe – so your credit score will get better over time as you pay back your debts. </p>
                <p>With a debt settlement service, the company works with your creditors to negotiate a reduction in the total amount of debt that you owe. </p>
                <p>
                    But one of the risks of debt settlement services is that it can
                    {' '}
                    <a href="https://www.debtconsolidation.com/loans/do-consolidation-loans-hurt-credit-score/">hurt your credit score</a>
                    {' '}
                    if you do not repay debts while waiting to negotiate a debt settlement.
                </p>
                <p>The Debt Management Plan offered by a consumer credit counseling service might be a better option if you are concerned about getting out of debt while also improving your credit score. </p>
                <p>People who sign up for a Debt Management Plan can see a significant increase in their credit scores and are less likely to declare bankruptcy, according to a Consumer Federation of America- and American Express-sponsored study of credit counseling clients.</p>
                <p>The study found that customers who signed up for a Debt Management Plan had an average increase in credit score of 59.4 points during the next three years (compared to 39.7 points for people who did not do a DMP. </p>
                <p>Also, among credit counseling customers who signed up for a Debt Management Plan, 15.9% declared bankruptcy during the next three years (compared with 28.4% of people who did not commit to a DMP).</p>
                <h3>The myth: credit counseling will hurt your credit score</h3>
                <p>According to MyFico.com, using a credit counseling service and having it noted on your credit score does not decrease your FICO Score. </p>
                <p>
                    <LazyLoadImage src="/media/average-fico-score-in-the-us-3.png" alt="Average FICO Score in the US" />
                </p>
                <p>However, the website also cautions that the actions you take based on a credit counselors recommendation could affect your score, such as settling for lesser amount, partial payments and any late payments.</p>
                <p>&quot;Even though it doesn&rsquo;t reduce your score, when a lender notes in the remarks on your report that your bills are being paid by a counseling agency, then a future lender may be hesitant to extend credit because you had a third party paying your bills,&quot; says Wayne Sanford, credit repair expert. </p>
                <p>&quot;Read your paperwork carefully and understand that the decisions you make will affect how future creditors look at you tomorrow.&quot;</p>
                <h2 id="8-questions-to-ask-your-credit-counselor">8 questions to ask your credit counselor</h2>
                <p>If you&rsquo;re considering credit counseling, then you&rsquo;re likely already in a tight spot with regard to your credit. </p>
                <p> The last thing you want to do is get buried deeper in debt by choosing the wrong person or firm to help.</p>
                <p>When you use a credit counselor, you invest a lot of trust that the person on the other side of the table will have the experience and expertise to help you get out of a jam you can&rsquo;t see a way out of on your own.</p>
                <p>So, when you&rsquo;ve chosen a counseling firm to visit in hopes of using its services, you should come armed with a series of questions to make sure it&rsquo;s right for you. </p>
                <p>
                    The answers to those
                    {' '}
                    <a href="http://www.consumer.ftc.gov/articles/0153-choosing-credit-counselor">questions will help you in picking a credit counselor</a>
                    {' '}
                    or in deciding that it would make more sense to seek help elsewhere.
                </p>
                <p>The best place to start – and potentially stop – is with this question:</p>
                <p>
                    <strong>What services are offered by your firm?</strong>
                    {' '}
                    <a href="http://www.consumer.ftc.gov/articles/0153-choosing-credit-counselor">The answer is vital</a>
                    {' '}
                    to whether there&rsquo;s any point in sticking around.
                </p>
                <p>The organization should offer a wide range of services, including education about debt, budgeting and alternatives for handling debt. </p>
                <p>If the sole focus is on a debt management plan, it&rsquo;s best to look elsewhere. </p>
                <p>While that could be the proper solution in the end, you need to find a counselor interested in exploring all the potential options.</p>
                <p>Other filtering questions would be:</p>
                <p>
                    <strong>Do you have some educational materials I can read about debt problems?</strong>
                    {' '}
                    Such information should be readily available and provided for free.
                </p>
                <p>If you are asked to pay for basic information like that, you should look elsewhere.</p>
                <p>
                    <strong>When we make a plan, will it include tools to help avoid a similar situation in the future? </strong>
                    {' '}
                    A reputable credit counselor will not only look at your current predicament, but aid you in moving forward.
                </p>
                <p>They should want to make sure you don&rsquo;t you get into the same jam again.</p>
                <p>
                    <strong>How much do you charge for your services and what if I can&rsquo;t afford to pay right now?</strong>
                    {' '}
                    Many agencies have a set-up fee and monthly charges while you are working with them.
                </p>
                <p>
                    They should put those in writing before you sign up. In addition, you should be able to find a qualified counselor at an agency that will be able to provide service
                    {' '}
                    <a href="http://debtadvice.org/">regardless of whether you can pay</a>
                    .
                </p>
                <p>Make sure to verify the agency&rsquo;s qualifications.</p>
                <p>
                    Ask about what the agency is accredited by either the
                    {' '}
                    <a href="http://debtadvice.org/">National Foundation for Credit Counseling</a>
                    {' '}
                    or the
                    {' '}
                    <a href="http://www.aiccca.org/">American Association of independent Credit Counseling</a>
                    .
                </p>
                <p>In addition, take pause if an agency tries to sign you up for their services without having had a thorough conversation about your situation.</p>
                <p>And you should ask these questions, too:</p>
                <p>
                    <strong>Are you certified to provide counseling services and, if so, by whom?</strong>
                    {' '}
                    It&rsquo;s&nbsp;important to know your counselor has a certification from an accredited organization in the type of work they are doing.
                </p>
                <p>If they do not have a certification, ask where they received their training. </p>
                <p>Ideally, it would be from a credible outside source.</p>
                <p>
                    <strong>How are the employees at the counseling firm paid? Do they receive bonuses or additional pay for signing me up for additional services?</strong>
                    {' '}
                    If the answer is that employees are working on some sort of bonus system, you should go elsewhere.
                </p>
                <p>Their interest should not be in how much business they drum up from you, but in how to best resolve your situation.</p>
                <p>
                    <strong>Are there any creditors your agency does not work with? If so, which?</strong>
                    {' '}
                    If they are companies you owe money to, it&rsquo;s important to know how those debts will be handled.
                </p>
                <p>
                    <strong>When I put money in an account for you to pay my creditors, how much of that goes to them and when?</strong>
                    {' '}
                    You need to know how your debts are going to be resolved.
                </p>
                <p>
                    <LazyLoadImage src="/media/contributors-to-credit-card-debt-4.png" alt="Contributors to Credit Card Debt" />
                </p>
                <p>And not every organization handles those payments in the same manner. </p>
                <p>Indeed, some less reputable firms might even keep your first payment, or more, as compensation for their work.</p>
                <p>In addition, you&rsquo;ll want to be sure that you get all proposed fees in writing. </p>
                <p>And don&rsquo;t sign any agreements until you have read what you are agreeing to and understand what it says.</p>
                <h3>Credit counseling can help, but you have to put in effort too</h3>
                <p>When you reach the point of going to a credit counselor, it&rsquo;s because you know there is a problem.</p>
                <p>Take the offered help and work through the reasons you went into debt in the first place: overspending, too many credit cards, etc. </p>
                <p>And then once you have a hold on your finances, use what you&rsquo;ve learned to avoid falling into the same traps again.</p>
                <p>Do you have experience with credit counseling services?</p>
                <p>Do you have any advice to share?</p>
                <p>Let us know in the comments below!</p>
            </>
        ),
    };

    return (
        <Post article={article} />
    );
};

export default HowItWorksCreditCounselingPage;
